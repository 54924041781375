<template>
  <div class="container-fluid g-0">
    <div class="row">
      <div class="col-md-12">
        <h4 class="mt-3">Project ({{ $route.params.id }})</h4>
      </div>
      <div class="col-md-12">
        <div v-if="error" class="row text-center">
          <p>{{ error }}</p>
        </div>
        <form class="row">
          <div class="mb-3 col-md-6">
            <label
              >Project*
              <input v-model="newProject.project" type="text" class="form-control" placeholder="Project" />
            </label>
          </div>
          <div class="mb-3 col-md-6">
            <label
              >Client
              <input v-model="newProject.client" type="text" class="form-control" placeholder="Client" />
            </label>
          </div>
          <div class="mb-3 col-md-6">
            <label
              >Per hour*
              <input v-model="newProject.perHour" type="number" class="form-control" placeholder="Per hour" />
            </label>
          </div>
          <div class="mb-3 col-md-6">
            <label
              >Currency
              <input v-model="newProject.perHourCurrency" type="text" class="form-control" placeholder="Currency" />
            </label>
          </div>

          <div class="mb-3 col-md-4">
            <label
              >Address*
              <input v-model="newProject.address" type="text" class="form-control" placeholder="Address" required />
            </label>
          </div>
          <div class="mb-3 col-md-4">
            <label
              >City*
              <input v-model="newProject.city" type="text" class="form-control" placeholder="City" required />
            </label>
          </div>
          <div class="mb-3 col-md-4">
            <label
              >Country*
              <input v-model="newProject.country" type="text" class="form-control" placeholder="Country" required />
            </label>
          </div>
          <div class="mb-3 col-md-6">
            <label
              >Website*
              <input v-model="newProject.website" type="text" class="form-control" placeholder="Website" required />
            </label>
          </div>
          <div class="mb-3 col-md-6">
            <label
              >Email*
              <input v-model="newProject.email" type="text" class="form-control" placeholder="Email" required />
            </label>
          </div>
          <div class="col-md-12">
            <button type="button" class="btn btn-primary text-white" @click.prevent="save()">Save</button>
            <button type="button" class="btn btn-danger text-white" @click.prevent="cancel()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'ProjectEdit',
  data() {
    return {
      newProject: {
        project: '',
        client: '',
        perHour: '',
        perHourCurrency: '',
        address: '',
        city: '',
        country: '',
        website: '',
        email: '',
      },
      error: '',
    };
  },
  computed: {
    ...mapGetters(['projects']),
  },
  methods: {
    ...mapActions(['getProjects', 'updateProject']),
    save() {
      const project = {
        id: this.$route.params.id,
        project: this.newProject.project,
        client: this.newProject.client,
        perHour: this.newProject.perHour,
        perHourCurrency: this.newProject.perHourCurrency,
        address: this.newProject.address,
        city: this.newProject.city,
        country: this.newProject.country,
        website: this.newProject.website,
        email: this.newProject.email,
      };

      this.updateProject(project);
      this.$router.push('/admin/projects');
    },
    cancel() {
      this.$router.push('/admin/projects');
    },
    async getAllProjects() {
      await this.getProjects();
      const temp = await this.projects.filter((project) => project.id == this.$route.params.id);
      if (temp.length === 0) {
        this.$router.push('/404');
      } else {
        this.newProject.id = temp[0].id;
        this.newProject.project = temp[0].project;
        this.newProject.client = temp[0].client;
        this.newProject.perHour = temp[0].perHour;
        this.newProject.perHourCurrency = temp[0].perHourCurrency;

        this.newProject.address = temp[0].address;
        this.newProject.city = temp[0].city;
        this.newProject.country = temp[0].country;
        this.newProject.website = temp[0].website;
        this.newProject.email = temp[0].email;
      }
    },
  },
  created() {
    this.getAllProjects();
  },
};
</script>

<style>
.btn,
button {
  margin-right: 10px;
}
</style>
